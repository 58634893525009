
import { firestore } from '@/firebase';
import { User } from '@/store/user/types';
import { deleteDoc, doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';

@Component
export default class ConfirmationDialog extends Vue {
    @VModel({ required: true }) show!: boolean;
    @Prop({ required: true }) user!: User;


    hasPassword = false;
    password = '';

    loading = false;

    async mounted() {
        const userPasswordDoc = await getDoc(doc(firestore, 'user-passwords', this.user.id))
        this.hasPassword = userPasswordDoc.exists()

        if (this.hasPassword) {
            this.password = userPasswordDoc.data()!.password
        }
    }


    async setPassword() {
        this.loading = true;
        const userPasswordDoc = await getDoc(doc(firestore, 'user-passwords', this.user.id))
        if (userPasswordDoc.exists()) await deleteDoc(doc(firestore, 'user-passwords', this.user.id))

        const newDoc = doc(firestore, 'user-passwords', this.user.id)

        onSnapshot(newDoc, (doc) => {
            if (doc.exists()) {
                if (doc.data()!.password) {
                    this.password = doc.data()!.password
                    this.hasPassword = true;
                    this.loading = false;
                }
            }
        })

        await setDoc(newDoc, {})
    }
}
